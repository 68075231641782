<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      default: "",
      type: String,
    },
  },

  computed: {
    ...mapGetters("config", ["temp_trades"]),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<template>
  <b-select v-model="model">
    <option v-for="(data, key) in temp_trades" :value="key" :key="key">
      {{ data.label }}
    </option>
  </b-select>
</template>
