<script>
import { stringSplit } from "@/utils/helpers";
export default {
  props: {
    value: {
      default: "0, max",
      type: String,
    },
    marks: {
      default: false,
      type: Boolean,
    },
    adsorb: {
      default: false,
      type: Boolean,
    },
    contained: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    steps() {
      let steps = [];
      let current = 0;
      let max = 25;
      while (current <= max) {
        steps.push(String(current));
        if (current >= 10) current += 15;
        else if (current >= 5) current += 5;
        else current += 1;
      }
      steps.push("+");
      return steps;
    },
    model: {
      get() {
        // return stringSplit(this.value, ",");
        let range = stringSplit(this.value, ",");
        let min = range[0] || "0";
        let max = range[1] || "max";
        if (max == "max") {
          max = "+";
        }
        return [min, max];
      },
      set(range) {
        // this.$emit("input", value.toString());
        let min = range[0] || "0";
        let max = range[1] || "+";
        if (max == "+") {
          max = "max";
        }
        this.$emit("input", [min, max].toString());
      },
    },
  },
};
</script>

<template>
  <div class="sitio-range" :class="{ 'has-marks': marks }">
    <vue-slider
      v-model="model"
      :data="steps"
      :adsorb="adsorb"
      :marks="marks"
      :contained="contained"
      lazy
      :dotSize="16"
    ></vue-slider>
  </div>
</template>

<style lang="scss">
.sitio-range.has-marks {
  padding-bottom: 1.5rem;
}
</style>
