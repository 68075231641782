<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { stringSplit } from "@/utils/helpers";
import _filter from "lodash/filter";
import _forEach from "lodash/forEach";
import _merge from "lodash/merge";
import _map from "lodash/map";
import _deburr from "lodash/deburr";
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      multiselect_value: [],
      search: "",
    };
  },
  computed: {
    model: {
      get() {
        let locations = stringSplit(this.value, ",");
        for (let index = 0; index < locations.length; index++) {
          locations[index] = +locations[index];
        }
        let objs = _filter(this.config_locations_full, o =>
          locations.includes(o.loc_id),
        );
        return objs;
      },
      set(locations) {
        locations = _map(locations, "loc_id");
        this.$emit("input", locations.toString());
      },
    },
    ...mapGetters("config", [
      "config_locations_groups",
      "config_locations_full",
    ]),
    filteredLocations() {
      if (!this.search.length) {
        return this.config_locations_groups;
      }
      let search = _deburr(this.search.toLowerCase());
      let groups = _merge([], this.config_locations_groups);
      _forEach(groups, (group, index) => {
        groups[index].group_items = _filter(group.group_items, item => {
          let name = _deburr(item.loc_name_long.toLowerCase());
          return name.indexOf(search) > -1;
        });
      });
      return groups;
    },
    textNoResult() {
      return "Oops! No se encontraron ubicaciones con estos términos de búsqueda.";
    },
  },
  methods: {
    searchChange(value) {
      this.search = value;
    },
  },
};
</script>

<template>
  <div>
    <multiselect
      v-model="model"
      :options="filteredLocations"
      :multiple="true"
      :internal-search="false"
      @search-change="searchChange"
      hideSelected
      group-values="group_items"
      group-label="group_label"
      placeholder="Escriba para buscar lugares"
      track-by="loc_id"
      label="loc_name_long"
    >
      <!-- <template slot="selection" slot-scope="{ values, search, isOpen }">
        <span
          class="multiselect__single"
          v-if="values.length &amp;&amp; !isOpen"
          >{{ values.length }} options selected</span
        >
      </template> -->
      <span slot="noResult">{{ textNoResult }}</span>
    </multiselect>
    <!-- <pre class="language-json"><code>{{ value }}</code></pre> -->
  </div>
</template>
