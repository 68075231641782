<script>
import { mapGetters } from "vuex";
import { stringSplit } from "@/utils/helpers";
export default {
  props: {
    value: {
      default: "",
      type: String,
    },
  },

  computed: {
    ...mapGetters("config", ["listings_amenities"]),
    selected: {
      get() {
        return stringSplit(this.value, ",");
      },
      set(value) {
        this.$emit("input", value.toString());
      },
    },
  },
};
</script>

<template>
  <div class="options-group finder-field-amenities">
    <div v-for="amenity in listings_amenities" :key="amenity.amenity_id">
      <b-checkbox v-model="selected" :native-value="amenity.amenity_id">
        {{ amenity.amenity_label_es }}
      </b-checkbox>
    </div>
  </div>
</template>
