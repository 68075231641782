<script>
import { mapGetters } from "vuex";
export default {
  props: {
    value: {
      default: "",
      type: String,
    },
  },

  computed: {
    ...mapGetters("config", ["listings_conditions"]),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", String(value));
      },
    },
  },
};
</script>

<template>
  <b-select v-model="model">
    <option value="0">Cualquiera</option>
    <option
      v-for="(option, key) in listings_conditions"
      :value="key"
      :key="key"
      >{{ option.label }}</option
    >
  </b-select>
</template>
