<script>
import _forEach from "lodash/forEach";
import _merge from "lodash/merge";
import _find from "lodash/find";
import _isEqual from "lodash/isEqual";
import { wpService } from "@/services/wp";
import finderFieldTrade from "@/components/finder-field-trade.vue";
import finderFieldRange from "@/components/finder-field-range.vue";
import finderFieldPrice from "@/components/finder-field-range-price.vue";
import finderFieldLocation from "@/components/finder-field-location.vue";
import finderFieldCategory from "@/components/finder-field-type.vue";
import finderFieldCondition from "@/components/finder-field-condition.vue";
import finderFieldAmenities from "@/components/finder-field-amenities.vue";
import finderFieldOrder from "@/components/finder-field-order.vue";
import Intersect from "vue-intersect";

const defaultFilters = {
  trade: "ltg_for_se",
  locations: "",
  ltg_cond: "0",
  amenities: "",
  ltg_room: "0,max",
  ltg_bath: "0,max",
  ltg_park: "0,max",
  price: "0,max",
  category: "0",
  show: "20",
  order: "DESC",
  orderby: "date",
  paged: "1",
};

export default {
  name: "finder",

  components: {
    Intersect,
    finderShowing: () =>
      import(
        /* webpackChunkName: "entries-showing" */ "@/components/entries-showing.vue"
      ),
    smfToggleLocations: () =>
      import(
        /* webpackChunkName: "smfToggleLocations" */ "@/components/finder-mobile-filter-locations-toggle.vue"
      ),
    mobileFilterLocations: () =>
      import(
        /* webpackChunkName: "mobileFilterLocations" */ "@/components/finder-mobile-filter-locations.vue"
      ),
    mobileFilterTrade: () =>
      import(
        /* webpackChunkName: "mobileFilterTrade" */ "@/components/finder-mobile-filter-trade.vue"
      ),
    mobileFilterGenerals: () =>
      import(
        /* webpackChunkName: "mobileFilterGenerals" */ "@/components/finder-mobile-filter-generals.vue"
      ),
    mobileFilterOrder: () =>
      import(
        /* webpackChunkName: "mobileFilterOrder" */ "@/components/finder-mobile-filter-order.vue"
      ),
    finderFieldTrade,
    finderFieldCategory,
    finderFieldRange,
    finderFieldPrice,
    finderFieldLocation,
    finderFieldCondition,
    finderFieldAmenities,
    finderFieldOrder,
    singleListing: () =>
      import(
        /* webpackChunkName: "single-listing" */ "@/components/single-listing.vue"
      ),
    finderEntry: () =>
      import(
        /* webpackChunkName: "finder-entry" */ "@/components/finder-entry.vue"
      ),
  },

  data() {
    return {
      entryHovering: false,
      ready: false,
      firstFetch: true,
      fetching: false,
      filters:
        this.$route.name === "finder"
          ? _merge({}, defaultFilters, this.$route.query)
          : defaultFilters,
      lastQuery: {},
      paged: this.$route.query.paged ? Number(this.$route.query.paged) : 1,
      result: {
        pages: 0,
        entries: [],
      },
      watchFilters: false,
      intersectOptions: false,
    };
  },

  // created() {
  //   let query = _merge({}, defaultFilters);
  //   if (this.$route.name === "finder") {
  //     query = _merge(query, this.$route.query);
  //   }
  //   this.filters = _merge({}, query);
  //   this.lastQuery = _merge({}, query);
  // },

  mounted() {
    this.watchFilters = true;
    this.ready = true;
  },

  computed: {
    listings() {
      let entries = this.result.entries || [];
      return entries;
    },
    queryFromFilters() {
      return _merge({}, this.filters, { paged: String(this.paged) });
    },
    queryFromURL() {
      return _merge({}, defaultFilters, this.$route.query);
    },
    isSingleListing() {
      let ltg_id = Number(this.$route.params.ltg_id) || 0;
      if (ltg_id) {
        return {
          ltg_id: ltg_id,
          data: _find(this.listings, { ltg_id: ltg_id }) || null,
        };
      }
      return null;
    },
  },

  methods: {
    resetFilters() {
      let reset = _merge({}, defaultFilters);
      if (!_isEqual(this.queryFromURL, reset)) {
        this.$router.push({ query: reset });
      }
    },
    updateFilters(filters = this.queryFromFilters) {
      // console.log("updateFilters");
      let current = _merge({}, this.filters);
      let updated = _merge({}, current, filters);
      if (!_isEqual(current, updated)) {
        // updated.paged = 0;
        if (updated.trade != current.trade) {
          updated.price = "0,max";
        }
      }
      if (this.$route.name !== "finder") {
        this.$router.replace({ name: "finder", query: updated });
      } else {
        this.$router.push({ name: "finder", query: updated });
      }
    },
    minQuery(query) {
      // let prevQ = _merge({}, defaultFilters, this.$route.query);
      query = _merge({}, defaultFilters, query);
      let minQuery = {};
      _forEach(query, (value, key) => {
        if (query[key] !== defaultFilters[key]) {
          minQuery[key] = value;
        }
      });
      return minQuery;
    },
    routeQuery(query = this.queryFromFilters) {
      // console.log("routeQuery");
      let lastQuery = _merge({}, this.queryFromFilters, this.lastQuery);
      query = _merge({}, lastQuery, query);
      if (!_isEqual(lastQuery, query)) {
        if (query.trade != lastQuery.trade) {
          query.price = "0,max";
        }
      }
      this.lastQuery = query;
      query = this.minQuery(query);
      if (
        this.$route.name !== "finder" &&
        this.$route.name !== "finder-single"
      ) {
        this.$router.replace({ name: "finder", query });
      } else {
        this.$router.push({ name: "finder", query });
      }
    },
    retrieve() {
      /**
       * Do not fetch listings while in a single
       */
      if (this.$route.name === "finder-single") return;
      localStorage.setItem(
        "last_search",
        JSON.stringify(this.queryFromFilters),
      );
      this.fetching = true;
      wpService.listings
        .query({ params: this.queryFromFilters })
        .then(response => {
          this.result = response;
        })
        .finally(() => {
          this.fetching = false;
          if (this.firstFetch) this.firstFetch = false;
        });
    },
    exitSingleListing() {
      this.routeQuery();
    },
  },

  watch: {
    ready(ready) {
      if (ready) this.retrieve();
    },
    $route(to, from) {
      if (to.name == "finder-single") return;
      /* Ignore if coming from single && it has been already fetched */
      if (from.name == "finder-single" && !this.firstFetch) return;
      let prevQuery = _merge({}, defaultFilters, from.query);
      let nextQuery = _merge({}, defaultFilters, to.query);
      /* Ignore if query is the same && it has been already fetched */
      if (_isEqual(prevQuery, nextQuery)) {
        if (this.firstFetch) {
          // console.log("$route change... same query but not yet fetched.");
          this.retrieve();
        }
        return;
      }
      /** If we made it here its a new query... fetch! */
      // console.log("$route watch... new query. Fetch it!");
      this.watchFilters = false;
      this.filters = _merge({}, nextQuery);
      this.lastQuery = _merge({}, nextQuery);
      this.paged = Number(nextQuery.paged) || 1;
      this.$nextTick(function() {
        this.watchFilters = true;
        this.retrieve();
      });
    },
    filters: {
      deep: true,
      handler: function(values) {
        if (!this.watchFilters || this.isSingleListing) return;
        // console.log("watch > filters");
        if (this.paged == 1) {
          this.routeQuery(values);
        } else {
          this.paged = 1;
        }
      },
    },
    paged() {
      if (this.watchFilters) this.routeQuery();
    },
  },
};
</script>

<template>
  <sitio-layout id="finder" v-show="ready" :class="{ fetching }">
    <b-loading :active="!ready || fetching" />
    <mq-layout
      mq="sm"
      class="app__view layout-small"
      v-show="$route.name.indexOf('finder-filter') < 0"
    >
      <div class="finderMobileFilterTog" :class="{ intersectOptions }">
        <div class="row">
          <smfToggleLocations :locations="filters.locations" />
        </div>
        <div class="row">
          <b-button
            class="filterToggle"
            tag="router-link"
            :to="{ name: 'finder-filter-trade', query: $route.query }"
            icon-right="angle-down"
            >Categoría</b-button
          >
          <b-button
            class="filterToggle"
            tag="router-link"
            :to="{ name: 'finder-filter-generals', query: $route.query }"
            icon-right="angle-down"
            >Filtros</b-button
          >
          <b-button
            class="filterToggle"
            tag="router-link"
            :to="{ name: 'finder-filter-order', query: $route.query }"
            icon-right="angle-down"
            >Orden</b-button
          >
        </div>
      </div>

      <Intersect
        @enter="intersectOptions = false"
        @leave="intersectOptions = true"
        rootMargin="40px 0px 0px 0px"
      >
        <div class="intersection" />
      </Intersect>
      <div class="results__content">
        <div class="entries" v-if="listings.length">
          <finderEntry
            v-for="entry in listings"
            :key="entry.ltg_id"
            :entry="entry"
            :searchTrade="filters.trade"
            @mouseover.native="entryHovering = true"
            @mouseleave.native="entryHovering = false"
          ></finderEntry>
        </div>
        <div class="no-results" v-else-if="!firstFetch">
          <div class="no-results-message">
            No hemos encontrado resultados para tu búsqueda.<br />
            ¡Modifica los filtros y prueba nuevamente!
          </div>
        </div>
        <div class="sitioPagination" v-if="result.pages > 1">
          <b-pagination
            class="is-primary"
            :total="result.found"
            :current.sync="paged"
            :range-before="1"
            :range-after="1"
            :simple="true"
            :rounded="false"
            :per-page="Number(filters.show)"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
          >
            <b-pagination-button
              slot-scope="props"
              :page="props.page"
              :id="`page${props.page.number}`"
              tag="button"
            >
              {{ props.page.number }}
            </b-pagination-button>

            <b-pagination-button
              slot="previous"
              slot-scope="props"
              :page="props.page"
              tag="button"
            >
              <b-icon icon="angle-left" />
            </b-pagination-button>

            <b-pagination-button
              slot="next"
              slot-scope="props"
              :page="props.page"
              tag="button"
            >
              <b-icon icon="angle-right" />
            </b-pagination-button>
          </b-pagination>
        </div>
      </div>
    </mq-layout>

    <mobileFilterTrade
      v-if="$mq == 'sm'"
      :active="$route.name == 'finder-filter-trade'"
      :value="filters.trade"
      @input="updateFilters({ trade: $event })"
    />
    <mobileFilterLocations
      v-if="$mq == 'sm'"
      :active="$route.name == 'finder-filter-locations'"
      :value="filters.locations"
      @input="updateFilters({ locations: $event })"
    />
    <mobileFilterGenerals
      v-if="$mq == 'sm'"
      :value="filters"
      @input="updateFilters($event)"
    />
    <mobileFilterOrder
      v-if="$mq == 'sm'"
      :active="$route.name == 'finder-filter-order'"
      :value="{ order: filters.order, orderby: filters.orderby }"
      @input="updateFilters($event)"
    />

    <mq-layout mq="md+" class="app__view layout-medium">
      <div id="desktop-filters">
        <simplebar id="filters__body" data-simplebar-auto-hide="true">
          <div class="widget">
            <div class="widget__header">
              <div class="label">Negociacón</div>
            </div>
            <div class="widget__body">
              <finderFieldTrade v-model="filters.trade" />
            </div>
          </div>

          <div class="widget">
            <div class="widget__header">
              <div class="label">Tipo de Propiedad</div>
            </div>
            <div class="widget__body">
              <finderFieldCategory v-model="filters.category" />
            </div>
          </div>

          <div class="widget">
            <div class="widget__header">
              <div class="label">Rango de precio</div>
            </div>
            <div class="widget__body">
              <finderFieldPrice
                :trade="filters.trade"
                v-model="filters.price"
                contained
              />
            </div>
          </div>

          <div class="widget">
            <div class="widget__header">
              <div class="label">Ubicación</div>
            </div>
            <div class="widget__body">
              <finderFieldLocation v-model="filters.locations" />
            </div>
          </div>

          <div class="widget">
            <div class="widget__header">
              <div class="label">Habitaciones</div>
            </div>
            <div class="widget__body">
              <finderFieldRange
                v-model="filters.ltg_room"
                marks
                contained
                adsorb
              />
            </div>
          </div>

          <div class="widget">
            <div class="widget__header">
              <div class="label">Baños</div>
            </div>
            <div class="widget__body">
              <finderFieldRange
                v-model="filters.ltg_bath"
                marks
                contained
                adsorb
              />
            </div>
          </div>

          <div class="widget">
            <div class="widget__header">
              <div class="label">Parqueos</div>
            </div>
            <div class="widget__body">
              <finderFieldRange
                v-model="filters.ltg_park"
                marks
                contained
                adsorb
              />
            </div>
          </div>

          <div class="widget">
            <div class="widget__header">
              <div class="label">Filtrar por condición</div>
            </div>
            <div class="widget__body">
              <finderFieldCondition v-model="filters.ltg_cond" />
            </div>
          </div>

          <div class="widget">
            <div class="widget__header">
              <div class="label">Filtrar por comodidades</div>
            </div>
            <div class="widget__body">
              <finderFieldAmenities v-model="filters.amenities" />
            </div>
          </div>
        </simplebar>
      </div>

      <div id="finder__results" :class="{ entryHovering }">
        <div class="results__bar">
          <finderShowing
            class="entries_showing"
            :show="Number(filters.show)"
            :paged="paged"
            :showing="listings.length"
            :found="result.found || 0"
          ></finderShowing>
          <finderFieldOrder
            class="entries_order_by"
            :value="{ order: filters.order, orderby: filters.orderby }"
            @input="routeQuery"
          ></finderFieldOrder>
        </div>
        <simplebar class="results__content" data-simplebar-auto-hide="true">
          <div class="entries" v-if="listings.length">
            <finderEntry
              v-for="entry in listings"
              :entry="entry"
              :key="entry.ltg_id"
              :searchTrade="filters.trade"
              @mouseover.native="entryHovering = true"
              @mouseleave.native="entryHovering = false"
            ></finderEntry>
          </div>
          <div class="no-results" v-else-if="!firstFetch">
            <div class="no-results-message">
              No hemos encontrado resultados para tu búsqueda.<br />
              ¡Modifica los filtros y prueba nuevamente!
            </div>
          </div>
          <div class="sitioPagination" v-if="result.pages > 1">
            <!-- <searchPagination :total="result.found" v-model="paged" /> -->
            <b-pagination
              class="is-primary"
              :total="result.found"
              :current.sync="paged"
              :range-before="1"
              :range-after="1"
              :simple="false"
              :rounded="false"
              :per-page="Number(filters.show)"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
              <b-pagination-button
                slot-scope="props"
                :page="props.page"
                :id="`page${props.page.number}`"
                tag="button"
              >
                {{ props.page.number }}
              </b-pagination-button>

              <b-pagination-button
                slot="previous"
                slot-scope="props"
                :page="props.page"
                tag="button"
              >
                <b-icon icon="angle-left" />
              </b-pagination-button>

              <b-pagination-button
                slot="next"
                slot-scope="props"
                :page="props.page"
                tag="button"
              >
                <b-icon icon="angle-right" />
              </b-pagination-button>
            </b-pagination>
          </div>
        </simplebar>
      </div>
    </mq-layout>

    <singleListing
      v-if="isSingleListing"
      :prefill="isSingleListing.data"
      @exit="exitSingleListing"
    />
  </sitio-layout>
</template>

<style lang="scss">
html[route^="finder-filter-"] {
  #app-bar-mobile {
    display: none !important;
  }
}
</style>

<style lang="scss">
#finder,
.finderMobileFilterView,
#desktop-filters,
#finder__results,
#finder__results .results__content {
  transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

// JUST LAYOUT
#finder {
  display: flex;
  flex-direction: column;
  padding: 0;

  .header_width {
    max-width: none;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .app__view {
    padding: 0;
  }

  .entries {
    display: flex;
    flex-wrap: wrap;
  }

  .entry,
  .slider-controls {
    transition: all 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .entry {
    width: 100%;
    position: relative;
    cursor: pointer;
    > .wrapper {
      position: relative;
    }
    // box-shadow: 0 4px 12px 1px rgba(0, 0, 0, 0.08);
    .gallery {
      margin-bottom: 0.5rem;
      border-radius: 0.25rem;
      overflow: hidden;
      will-change: transform; /* Fix iOS swipe overflow */
    }
    .entry__content {
      // padding: 0.75rem;
      padding-top: 0.25rem;
    }
    .entry-category {
      position: absolute;
      // transform: translateY(-180%);
      top: 0;
      // text-shadow: 1px 1px #0000005c;
      background-color: rgba($color: $sitio1, $alpha: 0.9);
      color: #fff;
      font-size: 0.875em;
      font-weight: bold;
      padding: 0.25rem 0.5rem;
      // padding-right: 1rem;
      border-bottom-right-radius: 0.25rem;
      border-top-left-radius: 0.25rem;
    }
    .entry-title {
      font-weight: 500;
      font-size: 1.375rem;
      // color: $primary;
      text-transform: capitalize;
    }
    .prices {
      font-size: 1em;
    }
    @media (min-width: 40em) {
      .entry-title {
        font-size: 1.125rem;
      }
    }
    @media (min-width: 64em) {
      &:not(:hover) {
        .slider-controls {
          opacity: 0;
        }
      }
      &:hover {
        background-color: #fff;
        box-shadow: 0px 72px 38px 9px rgba(0, 0, 0, 0.64);
        z-index: 999;
        .slider-controls {
          opacity: 1;
        }
      }
    }
  }

  .layout-small {
    .entries {
      // margin-top: 0.75rem;
      padding: 1.5rem;
    }
    .sitioPagination {
      padding: 1.5rem;
      margin-bottom: var(--safe-area-inset-bottom);
    }
    .entry {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  .layout-medium {
    flex: 1 1 auto;
    display: flex;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative;
    #desktop-filters {
      // flex: 0 0 18rem;
      flex: 0 0 16rem;
      height: 100%;
      background-color: #ffffff75;
      border-right: 1px solid #ecf0f4;
    }
    #finder__results {
      height: 100%;
      flex: 1 1 auto;
    }
    @media (max-width: 39.9375em) {
      #desktop-filters {
        display: none;
      }
    }
  }
}

.finderMobileFilterTog {
  transition: 0.1s all ease-out;
  width: 100%;
  box-shadow: 0px 3px 3px 0px rgba(46, 82, 107, 0.05);
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
  padding: 1rem 1.5rem;
  .row:not(:last-child) {
    margin-bottom: 1rem;
  }
  .filterToggle {
    border: 1px solid #dbdbdb;
    border-radius: 1.5rem;
    padding: 0 0.75rem;
    height: 2.25rem;
  }
  .button {
    font-weight: 500;
    font-size: 0.9375rem;
    .icon {
      color: $sitio1;
    }
  }
  .button:not(:first-child) {
    margin-left: 0.5rem;
  }
}

.simplify .finderMobileFilterTog {
  transform: translateY(-3.75rem);
  &.intersectOptions {
    transform: translateY(-100%);
  }
}

.finderMobileFilterView {
  &:not(.active) {
    display: none;
  }
  // background-color: #fff;
  // position: relative;
  z-index: 10;
  // position: fixed;
  position: relative;
  // min-height: 100vh;
  // top: 0;
  // left: 0;
  width: 100%;
  // height: 100%;
  // display: flex;
  // flex-direction: column;
  .widget {
    margin-bottom: 1.75rem;
  }
  .filters__bar {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #fff;
    box-shadow: 0px 3px 3px 0px rgba(46, 82, 107, 0.05);
    // flex: 0 0 4rem;

    .btn--close .button {
      border: 0;
      // padding: 1rem;
      width: 3rem;
      height: 3rem;
    }

    .btn--clear {
      position: absolute;
      right: 0;
      a {
        font-weight: bold;
        color: $primary;
        padding: 1rem;
      }
    }
  }
  .filters__body {
    width: 100%;
    padding: 1rem;
  }
  .filters__footer {
    position: fixed;
    bottom: 0;
    padding: 1rem;
    padding-bottom: calc(var(--safe-area-inset-bottom, 0rem) + 1rem);
    background-color: #fff;
    bottom: 0;
    width: 100%;
    border-top: $ui-border;
    box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.05);
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    .btn--finish {
      width: 100%;
      height: 3rem;
      font-weight: bold;
    }
  }
}

#finder #desktop-filters {
  position: relative;
  display: flex;
  flex-direction: column;
  #filters__bar,
  #filters__body,
  #filters__footer {
    padding: 1rem;
  }
  #filters__body {
    height: 100%;
    flex: 1 1 auto;
  }
  .label {
    font-size: 0.875rem;
    font-size: 0.9375rem;
  }
  .widget {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    flex-direction: column;
    .widget__header {
      padding: 0.25rem 0;
      font-weight: bold;
      font-size: 0.875rem;
      color: #363636;
    }
    .widget__body {
      width: 100%;
      .options-group {
        max-width: 100%;
      }
    }
  }
  .finder-field-amenities {
    .checkbox {
      align-items: flex-start;
    }
    .control-label {
      font-size: 0.9375rem;
    }
  }
}

#finder .layout-medium {
  input {
    font-size: 1em;
  }
  .select {
    font-size: 0.875em;
  }
  .vue-slider-mark-label {
    font-size: 0.8125rem;
  }
  .results__content {
    .entries {
      padding: 0.75rem;
    }
  }
  .entry {
    padding: 0.75rem;
  }
  .sitioPagination {
    padding: 1.5rem;
  }
}

#finder .no-results {
  padding: 1rem;
  padding-top: 15%;
  text-align: center;
}

// BODY STYLES
#finder__results {
  display: flex;
  flex-direction: column;
  .results__bar,
  .results__content {
    padding: 1rem;
    padding-bottom: var(--safe-area-inset-bottom, 1rem);
  }
  .results__bar {
    border-bottom: 1px solid #ecf0f4;
  }
  .simplebar-content {
    padding: 0 !important;
  }
  .results__content {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
    overflow-y: auto;
  }
  .entries_order_by {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 0.25rem;
    }
    .label {
      margin-right: 0.25rem;
      margin-bottom: 0;
    }
    // .select {
    //   font-size: 0.875em;
    //   display: block;
    // }
  }

  @media (min-width: 40em) {
    .results__bar,
    .results__content {
      padding: 1.25rem 1.5rem;
    }
  }

  @media (min-width: 50em) {
    .entry {
      width: 50%;
    }
  }

  // @media (min-width: 56.25em) {
  @media (min-width: 64em) {
    .entry {
      width: 33.333333333%;
    }
    &.entryHovering {
      .results__content {
        background-color: #eee;
      }
      // .entry:not(.hovering) {
      //   opacity: 0.5;
      // }
    }
  }

  @media (min-width: 80em) {
    .entry {
      width: 25%;
    }
  }

  @media (min-width: 100em) {
    .entry {
      width: 20%;
    }
  }
}
</style>
