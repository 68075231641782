<script>
import { stringSplit } from "@/utils/helpers";
import { mapGetters } from "vuex";
export default {
  props: {
    trade: {
      default: "default",
      type: String,
    },
    value: {
      default: "0,max",
      type: String,
    },
    marks: {
      default: false,
      type: Boolean,
    },
    adsorb: {
      default: false,
      type: Boolean,
    },
    contained: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("config", ["config_trades"]),
    anyPrice() {
      return this.value === "0,max";
    },
    steps() {
      let steps = [];
      let current = 0;
      let max = 25;
      while (current <= max) {
        steps.push(String(current));
        if (current >= 10) current += 15;
        else if (current >= 5) current += 5;
        else current += 1;
      }
      steps.push("+");
      return steps;
    },
    model: {
      get() {
        let values = stringSplit(this.value, ",");
        let min = values[0] || "0";
        let max = values[1] || "max";
        return [min, max];
      },
      set(range) {
        this.$emit("input", range.toString());
      },
    },
  },
  methods: {
    modTooltip(value) {
      if (value == "max") return "Max";
      else return `$${this.$root.$options.filters.formatMoney(value, 0)}`;
    },
  },
};
</script>

<template>
  <div class="sitio-range" :class="{ 'has-marks': marks }">
    <vue-slider
      v-if="config_trades.hasOwnProperty(trade)"
      v-model="model"
      :data="config_trades[trade].steps"
      :adsorb="adsorb"
      :marks="marks"
      :contained="contained"
      :tooltip-formatter="v => modTooltip(v)"
      :dotSize="16"
      lazy
    ></vue-slider>
    <small v-if="anyPrice">Cualquier precio</small>
    <small v-else-if="model[1] === 'max'">
      Desde USD${{ model[0] | formatMoney(0) }}
    </small>
    <small v-else-if="model[0] === '0'">
      Hasta USD${{ model[1] | formatMoney(0) }}
    </small>
    <small v-else>
      USD${{ model[0] | formatMoney(0) }} ~ USD${{ model[1] | formatMoney(0) }}
    </small>
  </div>
</template>

<style lang="scss">
.sitio-range.has-marks {
  padding-bottom: 1.5rem;
}
</style>
