<script>
import _findIndex from "lodash/findIndex";
export default {
  props: {
    value: {
      default() {
        return {
          order: "DESC",
          orderby: "date",
        };
      },
    },
  },

  computed: {
    modelSelected: {
      get() {
        return _findIndex(this.options, { value: this.value });
      },
      set(index) {
        this.$emit("input", this.options[index].value);
      },
    },
    options() {
      return [
        {
          label: "Fecha - Reciente a Antigua",
          value: {
            order: "DESC",
            orderby: "date",
          },
        },
        {
          label: "Fecha - Antigua a Reciente",
          value: {
            order: "ASC",
            orderby: "date",
          },
        },
        {
          label: "Precio - Mayor a Menor",
          value: {
            order: "DESC",
            orderby: "price",
          },
        },
        {
          label: "Precio - Menor a Mayor",
          value: {
            order: "ASC",
            orderby: "price",
          },
        },
      ];
    },
  },
};
</script>

<template>
  <div>
    <b class="label">Orden: </b>
    <b-select v-model="modelSelected">
      <option v-for="(option, index) in options" :key="index" :value="index">
        {{ option.label }}
      </option>
    </b-select>
    <!-- <b class="label">Orden: </b>
    <b-dropdown v-model="modelSelected" aria-role="list">
      <b-button slot="trigger" icon-right="chevron-down">
        <span>{{ options[modelSelected].label }}</span>
      </b-button>

      <b-dropdown-item
        v-for="(option, index) in options"
        :key="index"
        :value="index"
        aria-role="listitem"
      >
        <div class="media">
          {{ option.label }}
        </div>
      </b-dropdown-item>
    </b-dropdown> -->
  </div>
</template>
